import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Grid, Modal } from "@mui/material";
import Swal from "sweetalert2";
import Services from "../Services/Services";
import Cookies from "js-cookie";
import { decrypt } from "../Utils/cryptoUtils";
import { jwtDecode } from "jwt-decode";
import { encrypt } from "../Utils/cryptoUtils";
import { useLocation, useNavigate } from "react-router-dom";

const IvpProfile = ({ onClose, open }) => {
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobileno: "",
  });
  let navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation();

  const secureFlag =
    window.location.protocol === "https:"
      ? { secure: true, sameSite: "strict" }
      : { secure: false, sameSite: "lax" };

  const encryptedUserId = Cookies.get("abc");
  const uid = encryptedUserId ? encryptedUserId : null;
  const modalShown = localStorage.getItem("profile_modal_shown") === "true";
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (encryptedUserId) {
      const decryptedUid = decrypt(encryptedUserId);
      Services.IvpProfile(decryptedUid)
        .then((response) => {
          const userData = response.data.data;
          setUserData(userData);
          const data = response.data.data;
          const updatedProfileData = {
            first_name: data.first_name || "",
            last_name: data.last_name || "",
            email: data.email || "",
            mobileno: data.mobileno || "",
          };

          setProfileData(updatedProfileData);
          setDataLoaded(true);
          const anyFieldEmpty = Object.values(updatedProfileData).some(
            (field) => !field.trim()
          );
          setIsAnyFieldEmpty(anyFieldEmpty);
        })
        .catch((error) => {
          console.error("Error fetching profile:", error);
        });
    }
  }, [uid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // localStorage.getItem("encryptedQuizCode", encryptedQuizCode);
  // const encryptedQuizCode = decrypt(encryptedQuizCode);

  const handleSubmit = (e) => {
    e.preventDefault();
    const decryptedUid = decrypt(encryptedUserId);
    const updateData = {
      uid: decryptedUid,
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      email: profileData.email,
      mobileno: profileData.mobileno,
      mode: "ciso",
    };

    Services.updateProfile(updateData)
      .then((response) => {
        const bodyjson = {
          name: `${profileData.first_name} ${profileData.last_name}`,
          email_id: profileData.email,
          user_id: decryptedUid,
        };

        return Services.IvpProfileLogin(bodyjson);
      })
      .then((loginResponse) => {
        const { access_token, refresh_token, user_id } = loginResponse.data;
        Cookies.set("access_token", access_token, {
          expires: 1,
          ...secureFlag,
        });
        Cookies.set("refresh_token", refresh_token, {
          expires: 1,
          ...secureFlag,
        });
        Cookies.set("LoggedIn", true, { expires: 1, ...secureFlag });
        // const getLocal = window.localStorage.getItem("encryptedQuizCode");
        // const decryptedLocalValue = decrypt(getLocal);
        const decodeJWT = jwtDecode(access_token);
        const getLocal = window.localStorage.getItem("encryptedQuizCode");

        let decryptedLocalValue;
        try {
          decryptedLocalValue = decrypt(getLocal);
          console.log("Decrypted Local Value:", decryptedLocalValue);
        } catch (decryptionError) {
          console.error("Decryption error:", decryptionError);
        }

        const { role, email_id: email } = decodeJWT;
        console.log("role", role);

        // if (role === "user") {
        //   navigate("/dashboard");
        if (role === "user") {
          if (decryptedLocalValue) {
            navigate(`/quiz/${decryptedLocalValue}`);
          } else {
            navigate("/dashboard");
          }
        } else if (role === "instructor") {
          // navigate("/dashboard1");
          navigate("/instructor_dashboard",{ state: { role, email } });
        } else if (role === "reviewer") {
          navigate("/reviewerdashboard");
        } else if (role === "admin") {
          navigate("/admindashboard");
        } else if (role === "coordinator") {
          // navigate("/coordinatordashboard");
          navigate("/callback_requests");
        }

        setIsOpen(false);
        localStorage.setItem("profile_modal_shown", true);
        window.location.reload();
      })
      .catch((error) => {
        setIsOpen(false);
        const errorMessage =
          error.response?.data?.message || "Failed to update profile.";
        const statusCode = error.response?.status;

        let alertMessage = errorMessage;

        if (statusCode === 400) {
          alertMessage = `Bad Request: ${errorMessage}`;
        } else if (statusCode === 404) {
          alertMessage = `Not Found: ${errorMessage}`;
        } else if (statusCode === 500) {
          alertMessage = `Server Error: ${errorMessage}`;
        }

        setTimeout(() => {
          Swal.fire({
            title: "Error!",
            text: alertMessage,
            icon: "error",
          }).then(() => {
            setIsOpen(true);
          });
        }, 100);
      });
  };

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backdropFilter: "blur(4px)",
  };

  const paperStyle = {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    maxWidth: "1000px",
    width: "90%",
    height: "auto",
  };

  const isFormValid = Object.values(profileData).every(
    (field) => field.trim() !== ""
  );
  const isEmailDisabled = profileData.email.trim() !== "";
  console.log("is email", isEmailDisabled);
  const isMobileDisabled = profileData.mobileno.trim().length === 10;
  const isSkipDisabled = Object.values(profileData).some(
    (field) => field.trim() === ""
  );

  if (!modalShown && isOpen) {
    return (
      <Modal open={isOpen} onClose={() => {}} style={modalStyle}>
        <div style={paperStyle}>
          <Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>
            <span
              style={{ borderBottom: "3px solid red", paddingBottom: "3px" }}
            >
              Profile
            </span>
          </Typography>

          <Typography
            variant="h4"
            gutterBottom
            style={{
              marginTop: "30px",
              marginBottom: "40px",
              textAlign: "center",
            }}
          >
            Please provide the below details to access our Services
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="first_name"
                  value={profileData.first_name}
                  onChange={handleChange}
                  disabled={
                    dataLoaded &&
                    !!profileData.first_name &&
                    profileData.first_name === userData.first_name
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="last_name"
                  value={profileData.last_name}
                  onChange={handleChange}
                  disabled={
                    dataLoaded &&
                    !!profileData.last_name &&
                    profileData.last_name === userData.last_name
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  name="email"
                  value={profileData.email}
                  onChange={handleChange}
                  disabled={
                    dataLoaded &&
                    !!profileData.email &&
                    profileData.email === userData.email
                  }
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Mobile Number"
                  name="mobileno"
                  value={profileData.mobileno}
                  onChange={handleChange}
                  disabled={
                    dataLoaded &&
                    !!profileData.mobileno &&
                    profileData.mobileno === userData.mobileno
                  }
                  required
                  fullWidth
                />
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid}
                style={{ borderRadius: "20px" }}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

  return null;
};

export default (props) => <IvpProfile {...props} onClose={props.onClose} />;

// import React, { useEffect, useState } from 'react';
// import { TextField, Button, Typography, Grid, Modal } from '@mui/material';
// import Swal from 'sweetalert2';
// import Services from '../Services/Services';
// import Cookies from "js-cookie";
// import { decrypt } from "../Utils/cryptoUtils";

// const IvpProfile = ({ onClose }) => {
//     const [profileData, setProfileData] = useState({
//         first_name: '',
//         last_name: '',
//         email: '',
//         mobileno: '',
//     });

//     // const uid = Cookies.get("user_id");
//     const encryptedUserId = Cookies.get("abc");
//     const uid = encryptedUserId ? decrypt(encryptedUserId) : null;
//     const logged = Cookies.get("LoggedIn") === "true";
//     const modalShown = localStorage.getItem("profile_modal_shown") === "true";
//     const [errorMessage, setErrorMessage] = useState('');
//     const [isOpen, setIsOpen] = useState(true);
//     const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(true);

//     useEffect(() => {
//         if (uid) {
//             Services.IvpProfile(uid)
//                 .then((response) => {
//                     const data = response.data.data;
//                     const updatedProfileData = {
//                         first_name: data.first_name || '',
//                         last_name: data.last_name || '',
//                         email: data.email || '',
//                         mobileno: data.mobileno || '',
//                     };

//                     setProfileData(updatedProfileData);

//                     // Check if any field is empty after fetching data
//                     const anyFieldEmpty = Object.values(updatedProfileData).some(field => !field.trim());
//                     setIsAnyFieldEmpty(anyFieldEmpty);
//                 })
//                 .catch((error) => {
//                     console.error("Error fetching profile:", error);
//                 });
//         }
//     }, [uid]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setProfileData((prevData) => ({
//             ...prevData,
//             [name]: value,
//         }));
//     };

//     const handleSubmit = (e) => {
//       e.preventDefault();
//       const updateData = {
//           uid,
//           first_name: profileData.first_name,
//           last_name: profileData.last_name,
//           email: profileData.email,
//           mobileno: profileData.mobileno,
//           mode: "ciso",
//       };

//       Services.updateProfile(updateData)
//           .then((response) => {
//               setIsOpen(false);
//               setTimeout(() => {
//                   Swal.fire({
//                       title: 'Success!',
//                       text: response.data.message,
//                       icon: 'success',
//                   }).then(() => {
//                       localStorage.setItem("profile_modal_shown", true);
//                   });
//               }, 100);
//           })
//           .catch((error) => {
//               setIsOpen(false);
//               const errorMessage = error.response?.data?.message || 'Failed to update profile.';
//               const statusCode = error.response?.status;

//               let alertMessage = errorMessage;

//               if (statusCode === 400) {
//                   // alertMessage = Bad Request: ${errorMessage};
//               } else if (statusCode === 404) {
//                   // alertMessage = Not Found: ${errorMessage};
//               } else if (statusCode === 500) {
//                   // alertMessage = Server Error: ${errorMessage};
//               }

//               setTimeout(() => {
//                   Swal.fire({
//                       title: 'Error!',
//                       text: alertMessage,
//                       icon: 'error',
//                   }).then(() => {
//                       setIsOpen(true);
//                   });
//               }, 100);
//           });
//   };

//     const modalStyle = {
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         height: '100vh',
//         backdropFilter: 'blur(4px)',
//     };

//     const paperStyle = {
//         backgroundColor: 'white',
//         borderRadius: '8px',
//         padding: '20px',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//         maxWidth: '1000px',
//         width: '90%',
//         height: 'auto',
//     };

//     const isFormValid = Object.values(profileData).every(field => field.trim() !== '');
//     const isEmailDisabled = profileData.email.trim() !== '';
//     const isMobileDisabled = profileData.mobileno.trim().length === 10;
//     const isSkipDisabled = Object.values(profileData).some(field => field.trim() === '');

//     if (logged && !modalShown && isOpen) {
//         return (
//           <Modal open={isOpen} onClose={() => {}} style={modalStyle}>
//             <div style={paperStyle}>
//               <Typography variant="h2" gutterBottom style={{ textAlign: "center" }}>
//                 <span style={{ borderBottom: "3px solid red", paddingBottom: "3px" }}>
//                   Profile
//                 </span>
//               </Typography>

//               <Typography variant="h4" gutterBottom style={{ marginTop: "30px", marginBottom: "40px", textAlign: "center" }}>
//                 Please provide the below details to access our Services
//               </Typography>
//               <form onSubmit={handleSubmit}>
//                 <Grid container spacing={2}>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="First Name"
//                       name="first_name"
//                       value={profileData.first_name}
//                       onChange={handleChange}
//                       required
//                       fullWidth
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Last Name"
//                       name="last_name"
//                       value={profileData.last_name}
//                       onChange={handleChange}
//                       required
//                       fullWidth
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Email"
//                       name="email"
//                       value={profileData.email}
//                       onChange={handleChange}
//                       disabled={isEmailDisabled}
//                       required
//                       fullWidth
//                     />
//                   </Grid>
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       label="Mobile Number"
//                       name="mobileno"
//                       value={profileData.mobileno}
//                       onChange={handleChange}
//                       disabled={isMobileDisabled}
//                       required
//                       fullWidth
//                     />
//                   </Grid>
//                 </Grid>
//                 <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
//                   <Button
//                     type="button"
//                     variant="outlined"
//                     color="secondary"
//                     disabled={isAnyFieldEmpty}
//                     onClick={() => {
//                         localStorage.setItem("profile_modal_shown", true);
//                         setIsOpen(false);
//                         onClose();
//                     }}
//                     style={{ marginRight: "10px", borderRadius: '50px' }}
//                   >
//                     Skip
//                   </Button>
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     disabled={!isFormValid}
//                     style={{ borderRadius: '20px' }}
//                   >
//                     Submit
//                   </Button>
//                 </div>
//               </form>
//             </div>
//           </Modal>
//         );
//     }

//     return null;
// };

// export default (props) => <IvpProfile {...props} onClose={props.onClose} />;
